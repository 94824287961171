
import { defineComponent } from "vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";

export default defineComponent({
  name: "admin-credit-notes-table",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      debitNotes: [],
    };
  },
  created() {
    this.getDebitNotes();
  },
  methods: {
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    getDebitNotes() {
      //Fetch list of all debit notes
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_INVOICES_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.debitNotes = data.data;
            if (this.debitNotes) {
              this.displayDatatable();
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    displayDatatable() {
      $("#basicExample").DataTable({
        iDisplayLength: 10,
        language: {
          searchPlaceholder: "Search...",
          sSearch: "",
          lengthMenu: "_MENU_ items/page",
          zeroRecords: "No matching records found",
          infoEmpty: "No records available",
          infoFiltered: "(filtered from _MAX_ total records)",
        },
        processing: true,
      });
    },
  },
});
